<template>
    <div>
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
                <b-row>
                    <b-col md="9">
                        <b-card v-if="Object.keys(mhd_order).length > 0">
                            <b-card-body class="">
                                <b-row>
                                    <b-col cols="12">
                                        <b-img src="https://cdn.mhd.sa/asset/images/logo.png" width="100" />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" sm="6">
                                        <p>من:</p>
                                        <p>مؤسسة منصة مهد لتقنية المعلومات</p>
                                        <p>السجل التجاري: 1010686129</p>
                                        <p>الرقم الضريبي: 302150109600003</p>
                                    </b-col>
                                    <b-col cols="12" sm="6">
                                        <p>العميل:</p>
                                        <p>{{$store.state.store.legal_name}}</p>
                                        <p>السجل التجاري: {{$store.state.store.cr_number}}</p>
                                        <p>الرقم الضريبي: {{$store.state.store.vat_number}}</p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" class="mt-1">
                                        <h4 class="text-primary">{{$t('Order Details')}}</h4>
                                        <p>{{$t('ID')}}: {{mhd_order.id}}</p>
                                        <p>
                                            {{$t('Status')}}: 
                                            <b-badge :variant="mhd_order.status == 'completed' ? 'light-success' : 'light-warning'">
                                                {{mhd_order.status == 'completed' ? $t('Completed') : $t('Pending')}}
                                            </b-badge>
                                        </p>
                                        <p>{{$t('Date')}}: {{mhd_order.created_at | formatDate}}</p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" class="mt-1">
                                        <h4 class="text-primary">{{$t('Items')}}</h4>
                                        <div class="table-responsive">
                                            <table class="table b-table table-hover">
                                                <thead>
                                                <th>#</th>
                                                <th>{{$t('Item')}}</th>
                                                <th>{{$t('Price')}}</th>
                                                <th>{{$t('Quantity')}}</th>
                                                <th>{{$t('TAX')}}</th>
                                                <th>{{$t('Total')}}</th>
                                                </thead>
                                                <tbody>
                                                <template v-for="(mhd_order_item, index) in mhd_order.mhd_order_items">
                                                    <tr :key="index">
                                                        <td>{{index+1}}</td>
                                                        <td>
                                                            {{mhd_order_item.mhd_product.name}}<br>
                                                            <small>{{mhd_order_item.mhd_product_option.name}}</small>
                                                        </td>
                                                        <td>{{mhd_order_item.unit_price}} {{$t('SAR')}}</td>
                                                        <td>{{mhd_order_item.quantity}}</td>
                                                        <td>15%</td>
                                                        <td>
                                                            {{(mhd_order_item.unit_price * mhd_order_item.quantity) + ((mhd_order_item.unit_price * mhd_order_item.quantity) * 0.15)}} 
                                                            {{$t('SAR')}}
                                                        </td>
                                                    </tr>
                                                </template>
                                                </tbody>
                                            </table>
                                            <div>
                                            <dl class="row mt-1">
                                                <dt class="col-sm-3 font-weight-bolder">
                                                {{$t('Total before VAT')}}
                                                </dt>
                                                <dd class="col-sm-9">
                                                {{totalBeforeVAT}} {{$t('SAR')}}
                                                </dd>
                                            </dl>
                                            <dl class="row">
                                                <dt class="col-sm-3 font-weight-bolder">
                                                {{$t('VAT')}}
                                                </dt>
                                                <dd class="col-sm-9">
                                                {{totalVAT}} {{$t('SAR')}}
                                                </dd>
                                            </dl>
                                            <dl class="row text-primary">
                                                <dt class="col-sm-3 font-weight-bolder">
                                                {{$t('Final Total')}}
                                                </dt>
                                                <dd class="col-sm-9">
                                                {{finalTotal}} {{$t('SAR')}}
                                                </dd>
                                            </dl>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
export default {
    directives: {
        Ripple,
    },
    mounted() {
        this.getResults()
    },
    data() {
        return {
            mhd_order: {},
            isLoading: true,
            totalBeforeVAT: 0,
            totalVAT: 0,
            finalTotal: 0,
        }
    },
    methods: {
        getResults() {
            useJwt.post('/mhd-orders/get-by-id',{id: this.$route.params.id})
            .then((response) => {
                console.log(response.data)
                this.mhd_order = response.data.data
                this.isLoading = false

                this.mhd_order.mhd_order_items.forEach(mhd_order_item => {
                    let productTotal = mhd_order_item.unit_price * mhd_order_item.quantity
                    this.totalBeforeVAT += productTotal
                    this.totalVAT += productTotal * .15
                });
                this.finalTotal = this.totalBeforeVAT + this.totalVAT
            })
            .catch(response => {
                // console.log(response);
            });
        },
    },
}
</script>

<style lang="sass" scoped>

</style>
